<template>
 <div>
   <router-view></router-view>
 </div>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style lang="less" scoped>
</style>
